.notfound {
  font-size: 64px;
  font-weight: bold;
  text-align: center; }
.notfound-extended {
  font-size: 14px;
  text-align: center; }
.messages-wrapper {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100% - 55px - 55px) !important;
  overflow-y: scroll;
  overflow-x: hidden; }
.liveClass {
  background:#f26b25 {}
  border-radius: 5px;
  border: 1px solid rgba(218, 220, 224, 0.5); }

