@import "./Settings";

.leftnav {
  height: 100%; }
.leftnav {
  min-width: $nav-width;
  background: #2550A1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //overflow-y: scroll;

  //overflow: hidden
  //overflow-y: auto
  .profile {
    position: relative;
    margin: 40px 18px 0 18px;
    cursor: pointer;
    height: 0;
    .picture {
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 30px; }
    .img {
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      background: #f7f7f8;
      color: $nav-background;
      font-size: 24px; }
    .status {
      position: absolute;
      top: 41px;
      left: 41px;
      width: 14px;
      height: 14px;
      border-radius: 11px;
      border: 3px solid $nav-background; }
    .status.online {
      background: #55d48b; }
    .status.away {
      background: orange; }
    .status.busy {
      background: red; } }
  .button {
    height: 65px;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    flex-direction: column; }
  .button.first {
    margin-top: 100px; }
  .button.active {
    color: #f7f7f8;
    //background: #363e47
 }    //border-left: 4px solid #f26b25
  .button:hover {
 } }    //background: #363e47
